import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { apiObject } from '@idealmediaworks/lookingglass-ui-framework';
import {
  Box,
  Button,
  makeStyles,
  Popover,
  Typography,
} from '@material-ui/core';
import { format, isAfter, isBefore, isEqual, startOfDay } from 'date-fns';
import classnames from 'classnames';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { toJS } from 'mobx';
import ChannelMonth from '../channelMonth/ChannelMonth';
import Grid from '../../../components/grid/Grid';
import DownloadButton from './DownloadButton';
import Message from '../../../components/message/Message';
import { IChannel } from '../../../models/hierarchy/channel';
import { showErrorNotification, showSuccessNotification } from '../../../utils';
import { browserDownloadFromResponse } from '../../../utils/api';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(0.5),

      '& .calendar': {
        margin: theme.spacing(0.5),
      },

      '& .calendar-paper.MuiPaper-root': {
        overflow: 'hidden',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.default,
        // border: '1px solid #161616',
      },

      '& .calendar .MuiTypography-body1': {
        // marginTop: theme.spacing(1),
      },

      '& .calendar .MuiPickersCalendar-transitionContainer': {
        marginTop: theme.spacing(1),
        minHeight: theme.spacing(23.5),
      },

      '& .calendar .MuiPickersCalendarHeader-iconButton': {
        display: 'none',
      },

      '& .calendar .MuiPickersCalendarHeader-dayLabel': {
        width: theme.spacing(3.375),
      },

      '& .calendar .day-button': {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
        margin: 1,
        padding: 0,
        minWidth: 'auto',

        background: '#e16a6a',
        // border: '1px solid transparent',
        borderRadius: 2,
        letterSpacing: 0,
      },
      '& .calendar .day-button:hover': {
        // borderColor: '#954e4e',
        background: '#d05e5e',
      },

      '& .calendar .day-button--isAvailable': {
        background: '#3b973b',
        color: 'white',
      },
      '& .calendar .day-button--isAvailable:hover': {
        // borderColor: '#1e551e',
        background: '#318431',
      },

      '& .calendar .day-button--isCurrentDay, & .calendar .day-button--isCurrentDay:hover':
        {
          background: '#135fac',
          // borderColor: '#135fac',
          color: 'white',
          pointerEvents: 'none',
        },

      '& .calendar .day-button--isGrayed': {
        opacity: 0.3,
        pointerEvents: 'none',
        background: 'transparent',
        color: 'inherit',
      },

      '& .calendar .day-button--isSelected, & .calendar .day-button--isCurrentDay:hover':
        {
          background: '#ff9800',
          color: 'white',
        },

      '& .calendar .day-button--isDisabled': {
        opacity: '0 !important',
        pointerEvents: 'none !important',
        background: 'transparent !important',
        color: 'inherit !important',
      },
    },
  }),
  { name: 'ChannelMonthsGrid' }
);

interface Props {
  channel: IChannel;
}

const ChannelsMonthsGrid: React.FC<Props> = ({ channel }): JSX.Element => {
  const styles = useStyles();

  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [isAvailable, setIsAvailable] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedAliceName, setSelectedAliceName] = useState<string | null>(
    null
  );
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const { asRunDays, firstDate, generatedMonths, channelMetadata, address } =
    channel;

  const closePopover = () => {
    setPopoverAnchorEl(null);
    setIsAvailable(false);
    setSelectedDate(null);
  };

  const openPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    { date, aliceName, fileName, isAvaiable }: any
  ) => {
    setSelectedDate(date);
    setSelectedAliceName(aliceName);
    setSelectedFileName(fileName);
    setIsAvailable(isAvaiable);
    openPopover(e);
  };

  const handleDownload = (targetSystem: string) => {
    apiObject()
      .get(`/address/${address}.asruns.${selectedAliceName}`, {
        headers: {
          'content-Type': 'application/octet-stream',
        },
        params: {
          targetSystem,
        },
        responseType: 'blob',
      })
      .then((res: any) => {
        browserDownloadFromResponse(res, selectedAliceName);
        showSuccessNotification(`Downloading started ${selectedFileName}`);
      })
      .catch((err: any) => {
        showErrorNotification(err.message);
      })
      .finally(() => {
        closePopover();
      });
  };

  const handleRenderDay = (
    dayDate: MaterialUiPickersDate,
    calendarSelectedDate: MaterialUiPickersDate,
    isInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    const now = startOfDay(new Date());
    const dayLabel = dayDate?.getDate();
    let formatedLabel: string | null = null;
    let asRunDay: any = null;

    if (dayDate) {
      if (channelMetadata?.asrunFileDateNotation) {
        formatedLabel = format(dayDate, channelMetadata.asrunFileDateNotation);
      } else if (channelMetadata?.inputFileDateNotation) {
        formatedLabel = format(dayDate, channelMetadata.inputFileDateNotation);
      }
    }

    if (formatedLabel) {
      if (asRunDays) {
        const key = Object.keys(toJS(asRunDays)).filter(
          (item) => item.indexOf(formatedLabel as string) > -1
        )[0];
        asRunDay = key ? asRunDays.get(key) : null;
      }
    }

    const isAsRunDayAvailable = asRunDay ? asRunDay.isAvailable : false;
    const fileName = asRunDay ? asRunDay.fileName : null;
    const aliceName = asRunDay ? asRunDay.aliceName : null;

    const isSelected =
      dayDate && selectedDate ? isEqual(dayDate, selectedDate) : false;
    const isCurrentDay = dayDate ? isEqual(dayDate, now) : false;
    const isDayBeforeFirstDate = dayDate ? isBefore(dayDate, firstDate) : false;
    const isDayEqual = dayDate ? isEqual(dayDate, firstDate) : false;
    const isDayAfterFirstDate = dayDate ? isAfter(dayDate, firstDate) : false;
    const isDayAfterNow = dayDate ? isAfter(dayDate, now) : false;
    const isBetween =
      (!isDayBeforeFirstDate && isDayAfterFirstDate && !isDayAfterNow) ||
      isDayEqual;

    const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      handleClick(e, {
        date: dayDate,
        fileName,
        aliceName,
        isAvaiable: isAsRunDayAvailable,
      });
    };

    return (
      <Button
        onClick={onClick}
        className={classnames('day-button', {
          'day-button--isSelected': isSelected,
          'day-button--isAvailable': isAsRunDayAvailable,
          'day-button--isDisabled': !isInCurrentMonth,
          'day-button--isGrayed': !isBetween,
          'day-button--isCurrentDay': isCurrentDay,
        })}
      >
        {dayLabel}
      </Button>
    );
  };

  return (
    <div className={styles.root}>
      <Grid>
        {generatedMonths.map((date) => {
          const now = new Date(date);

          return (
            <ChannelMonth
              key={date}
              date={now}
              handleRenderDay={handleRenderDay}
            />
          );
        })}
      </Grid>

      <Popover
        open={isAvailable && Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={1}>
          <Message type="ASRUNS_AVAIABLE_FOR_DATE" inline />

          <Box p={1} textAlign="center" maxWidth={200} m="0 auto">
            <Typography style={{ wordWrap: 'break-word' }}>
              {selectedFileName}
            </Typography>
          </Box>

          <Box p={2} display="flex" justifyContent="center">
            <Button onClick={closePopover} style={{ color: '#bdbdbd' }}>
              Cancel
            </Button>
            <DownloadButton onDownload={handleDownload} />
          </Box>
        </Box>
      </Popover>

      <Popover
        open={!isAvailable && Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Message type="ASRUNS_NOT_AVAIABLE_FOR_DATE" inline />
      </Popover>
    </div>
  );
};

export default ChannelsMonthsGrid;
