import React from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
// import styles from './Preloader.module.scss';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },

    '&$isFullPage': {
      height: '100%',
    },

    '&$isInline': {
      justifyContent: 'flex-start',

      '& > div': {
        flexDirection: 'row',
        alignItems: 'center',
      },

      '& $icon': {
        margin: theme.spacing(1),
      },

      '& $label': {
        marginBottom: theme.spacing(1),
        maxWidth: 'none',
      },
    },
  },

  icon: {
    margin: theme.spacing(1, 'auto'),
  },

  label: {
    textTransform: 'uppercase',
    letterSpacing: 2,
    maxWidth: 250,
    textAlign: 'center',
    lineHeight: '140%',
    fontSize: '.8em',
    animation: '$blink 2s infinite',
  },

  '@keyframes blink': {
    '0%': {
      opacity: 0.4,
      letterSpacing: 2,
    },

    '50%': {
      opacity: 1,
      letterSpacing: 3,
    },

    '100%': {
      opacity: 0.4,
      letterSpacing: 2,
    },
  },

  isFullPage: {},
  isInline: {},
}));

interface Props {
  message?: string;
  icon?: JSX.Element;
  isFullPage?: boolean;
}

const Preloader: React.FC<Props> = ({
  isFullPage,
  icon,
  message,
}): JSX.Element => {
  const styles = useStyles();

  return (
    <div
      className={classNames(styles.root, { [styles.isFullPage]: isFullPage })}
    >
      <div>
        <div className={styles.icon}>{icon}</div>
        {message && <div className={styles.label}>{message}</div>}
      </div>
    </div>
  );
};

Preloader.defaultProps = {
  icon: <CircularProgress color="primary" />,
  isFullPage: false,
};

export default Preloader;
