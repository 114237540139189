import React, { useState, useEffect, useRef } from 'react';
import { apiObject } from '@idealmediaworks/lookingglass-ui-framework';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, useTheme } from '@material-ui/core';
import { absolemAppUrl } from '../../../config';

const options = ['Preview RAW', 'Preview ENRICHED', 'Preview BOTH'];

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function SplitButton({ enabled, address, file }) {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const theme = useTheme();
  const [enrichExists, setEnrichExists] = useState(false);

  useEffect(() => {
    if (enabled) {
      const check = async (group) => {
        try {
          await apiObject().get(`/address/${address}.${group}.${file}`);

          setEnrichExists(true);
        } catch (err) {
          console.log('No enriched rats');
        }
      };

      // check('rats_schedules');
      check('enriched_rats');
    }
  }, [enabled, address, file]);

  const handleClick = () => {
    if (options[selectedIndex] === 'Preview RAW') {
      window.open(
        `${absolemAppUrl}/?address=${address}.rats_schedules.${file}&theme=${theme.palette.type}`
      );
    }

    if (options[selectedIndex] === 'Preview ENRICHED') {
      window.open(
        `${absolemAppUrl}/?address=${address}.enriched_rats.${file}&theme=${theme.palette.type}`
      );
    }

    if (options[selectedIndex] === 'Preview BOTH') {
      window.open(
        `${absolemAppUrl}/diff/?address=${address}.enriched_rats.${file}&diff=${address}.rats_schedules.${file}&theme=${theme.palette.type}`
      );
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          variant="contained"
          color="primary"
          ref={anchorRef}
          disabled={!enabled}
        >
          <Button size="small" onClick={handleClick}>
            {options[selectedIndex]}
          </Button>
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper className={styles.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={!enrichExists}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
