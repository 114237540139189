export const browserDownloadFromResponse = (res, fileName) => {
  // eslint-disable-next-line no-undef
  const url = window.URL.createObjectURL(new Blob([res.data]));
  // eslint-disable-next-line no-undef
  const link = document.createElement('a');

  const fileNameWithExtension = `${fileName}${res.headers.objectextension}`;

  link.href = url;
  link.setAttribute('download', fileNameWithExtension); // or any other extension
  link.setAttribute('id', 'temporaryAnchor');
  link.style.display = 'none';
  // eslint-disable-next-line no-undef
  document.body.appendChild(link);
  link.click();
  // eslint-disable-next-line no-undef
  document.getElementById('temporaryAnchor').remove();
};
