import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { ToastContainer } from 'react-toastify';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import AppRouter from './core/AppRouter';
import { rootStore } from './models';
import { logVersion } from './utils/version';

// import './style/index.scss';
// import theme from './style/js/theme';
import 'react-toastify/dist/ReactToastify.css';

logVersion();

ReactDOM.render(
  <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider rootStore={rootStore}>
        <AppRouter />
      </Provider>
    </MuiPickersUtilsProvider>
    <ToastContainer />
  </>,
  document.getElementById('root')
);
