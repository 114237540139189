import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    // margin: 0 -0.5rem;
    // overflow: auto;
    // padding: 0.5em 0;
  },
}));

const Grid: React.FC = ({ children }): JSX.Element => {
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
};

export default Grid;
