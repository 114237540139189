import isNull from 'lodash.isnull';

export const sortByKey = (arr: any, key: string) => {
  arr.sort((a: any, b: any) => {
    if (isNull(a) || isNull(b)) {
      return 0;
    }

    if (a[key] < b[key]) {
      return -1;
    }

    if (a[key] > b[key]) {
      return 1;
    }

    return 0;
  });
};
