import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    tab: {
      borderBottom: '2px solid #26292c', // theme.palette.background[800]
      maxWidth: '100%',
      minHeight: theme.spacing(6),
      transition: '.2s',
      opacity: 1,
      fontSize: '.8em',
      letterSpacing: 2,

      '&:hover': {
        backgroundColor: 'rgba(255,255,255,.1)',
      },

      '&$selected': {
        fontWeight: 'bold',
        background: theme.palette.background.default,
        borderBottomColor: theme.palette.primary.main,
      },
    },
    selected: {},
  }),
  { name: 'TabsList' }
);

function a11yProps(itemId: any) {
  return {
    id: `scrollable-auto-tab-${itemId}`,
    'aria-controls': `scrollable-auto-tabpanel-${itemId}`,
  };
}

interface ListElem {
  name: null | string;
  id: string;
}

interface Props {
  list: (ListElem | null)[];
  changeSelectedItem: (id: string) => void;
  selectedItem: any;
}

const TabList: React.FC<Props> = observer((props): JSX.Element => {
  const { list, selectedItem, changeSelectedItem } = props;
  const styles = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, id: string) => {
    changeSelectedItem(id);
  };

  return (
    <Tabs
      orientation="vertical"
      value={selectedItem?.id}
      onChange={handleChange}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    >
      {list.map((elem) => {
        if (elem === null) return null;

        return (
          <Tab
            classes={{
              root: styles.tab,
              selected: styles.selected,
            }}
            component={React.forwardRef((componentProps: any, ref) => {
              return (
                <span ref={ref} {...componentProps}>
                  {componentProps.children}
                </span>
              );
            })}
            value={elem.id}
            key={elem.id}
            label={elem.name}
            {...a11yProps(elem.id)}
          />
        );
      })}
    </Tabs>
  );
});

export default TabList;
