import { Instance, onSnapshot, types } from 'mobx-state-tree';
import { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { useObserver } from 'mobx-react-lite';

import Hierarchy, { hierarchyInitialState, IHierarchy } from './hierarchy';

const RootModel = types.model({
  hierarchy: Hierarchy,
});

const initialState = {
  hierarchy: hierarchyInitialState,
};

export const rootStore = RootModel.create(initialState);

export type IRootModel = Instance<typeof RootModel>;

onSnapshot(rootStore, (snapshot) => console.log('Snapshot: ', snapshot));

const useStores = () => {
  return useContext(MobXProviderContext);
};

export const useHierarchyStore = (): IHierarchy => {
  const { hierarchy } = useStores().rootStore;

  return useObserver(() => hierarchy);
};
