import React, { useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Workspace } from '@idealmediaworks/lookingglass-ui-framework';
import { useHierarchyStore } from '../../models';
import Message from '../../components/message/Message';
import Preloader from '../../components/preloader/Preloader';

const AsRunsPage: React.FC = (): JSX.Element => {
  const { selectedRegion, hasError, messageType, initAsRuns } =
    useHierarchyStore();

  useEffect(() => {
    if (hasError) return;

    initAsRuns();
  }, [hasError, initAsRuns]);

  if (selectedRegion?.isLoading) {
    return <Preloader isFullPage message="Please wait" />;
  }

  if (hasError && messageType) {
    return <Message type={messageType} isFullPage />;
  }

  const layout = {
    version: '0.2.0',
    id: 'workspace-asruns',
    direction: 'row',
    panes: [
      {
        id: 'pane-side',
        size: 13,
        type: 'percent',
        layout: {
          id: 'grid-side',
          direction: 'column',
          panes: [
            {
              id: 'pane-regions',
              size: 15,
              type: 'percent',
              panels: ['panel-regions'],
            },
            {
              id: 'pane-brands',
              size: 35,
              type: 'percent',
              panels: ['panel-brands'],
            },
            {
              id: 'pane-channels',
              size: 50,
              type: 'percent',
              panels: ['panel-channels'],
            },
          ],
        },
      },
      {
        id: 'pane-content',
        size: 87,
        type: 'percent',
        layout: {
          id: 'grid-content',
          direction: 'column',
          panes: [
            {
              id: 'pane-months',
              size: 100,
              type: 'percent',
              panels: ['panel-months'],
            },
          ],
        },
      },
    ],
  };

  return <Workspace layout={layout} key={layout.id} />;
};

export default AsRunsPage;
