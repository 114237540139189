import React from 'react';
import { observer } from 'mobx-react';
import { useHierarchyStore } from '../../../models';
import Message from '../../../components/message/Message';
import { DebugMessageTypes } from '../../../models/hierarchy';
import ChannelsMonthsGrid from './ChannelMonthsGrid';
import Preloader from '../../../components/preloader/Preloader';

const ChannelsMonths: React.FC = (): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();
  const selectedChannel = selectedRegion?.selectedBrand?.selectedChannel;

  if (!selectedChannel?.channelMetadata?.enableAsrun) {
    return <Message type="ASRUNS_EMPTY" isFullPage />;
  }

  if (selectedChannel?.channelMetadata?.asrunDays === 0) {
    return <Message type="ASRUNS_DAYS_EMPTY" isFullPage />;
  }

  if (selectedChannel.generatedMonths.length === 0) {
    return <Message type="ASRUNS_DAYS_EMPTY" isFullPage />;
  }

  if (
    !selectedChannel?.channelMetadata?.asrunFileDateNotation &&
    !selectedChannel?.channelMetadata?.inputFileDateNotation
  ) {
    return <Message type="ASRUNS_DATE_NOTATION" isFullPage />;
  }

  if (!selectedChannel?.asRunDays) {
    return <Preloader isFullPage />;
  }

  if (selectedChannel?.channelMetadata?.asrunDays > 180) {
    console.info(DebugMessageTypes.asrunsDaysExeedsLimit);
  }

  return <ChannelsMonthsGrid channel={selectedChannel} />;
};

export default observer(ChannelsMonths);
