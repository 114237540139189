import React from 'react';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';
import { useHierarchyStore } from '../../../models';
import ChannelDayBox from '../channelDay/ChannelDayBox';
import { IChannelDay } from '../../../models/hierarchy/channelDay';
import Message from '../../../components/message/Message';
import Preloader from '../../../components/preloader/Preloader';

const ChannelsArea: React.FC = (): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();

  if (!selectedRegion?.id) {
    return <Message type="NO_REGION_SELECTED" isFullPage />;
  }

  if (!selectedRegion.selectedBrand?.id) {
    return <Message type="NO_BRAND_SELECTED" isFullPage />;
  }

  if (selectedRegion.selectedBrand.channels.length === 0) {
    return <Message type="NO_CHANNELS" isFullPage />;
  }

  if (!selectedRegion.selectedBrand.selectedChannel?.id) {
    return <Message type="NO_CHANNEL_SELECTED" isFullPage />;
  }

  if (selectedRegion.selectedBrand.selectedChannel.isLoading) {
    return <Preloader isFullPage />;
  }

  if (selectedRegion.selectedBrand.selectedChannel.channelsDays.length === 0) {
    return <Message type="CHANNEL_DAYS_EMPTY" isFullPage />;
  }

  return (
    <div style={{ padding: 10, minWidth: '580px' }}>
      <Typography
        variant="h5"
        style={{ fontWeight: 600, textTransform: 'uppercase' }}
      >
        {selectedRegion.selectedBrand.selectedChannel.name}
      </Typography>
      <Typography>
        {selectedRegion.selectedBrand.selectedChannel.address}
      </Typography>
      <br />

      {selectedRegion.selectedBrand.selectedChannel.channelsDays.map(
        (channelDay: IChannelDay, index: number) => {
          const channelAddress =
            selectedRegion?.selectedBrand?.selectedChannel?.address;

          const channelFileExtension =
            selectedRegion?.selectedBrand?.selectedChannel?.channelMetadata
              ?.inputFileExtension;

          // todo: change index key to an id
          return (
            <ChannelDayBox
              key={index}
              channelDay={channelDay}
              channelAddress={channelAddress}
              channelFileExtension={channelFileExtension}
            />
          );
        }
      )}
    </div>
  );
};

export default observer(ChannelsArea);
