import { toast } from 'react-toastify';

export const showSuccessNotification = (msg?: string): void => {
  const message = msg || 'Success';

  toast.success(message, {
    autoClose: 2500,
  });
};

export const showErrorNotification = (msg?: string): void => {
  const message = msg || 'Error';

  toast.error(message);
};
