import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useKeycloak } from '@react-keycloak/web';
import Preloader from './preloader/Preloader';
import { useHierarchyStore } from '../models';

const AppInit: React.FC = ({ children }): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { getHierarchyData, isLoading: hierarchyIsLoading } =
    useHierarchyStore();

  useEffect(() => {
    getHierarchyData(keycloak);
  }, [getHierarchyData]);

  return (
    <>
      {hierarchyIsLoading ? (
        <Preloader message="Down The Rabbit Hole" isFullPage />
      ) : (
        children
      )}
    </>
  );
};

export default observer(AppInit);
