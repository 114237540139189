import cloneDeep from 'lodash.clonedeep';
import { hasUserAccess } from './adminUtils';

enum DataNodeTypes {
  regions = 'regions',
  brands = 'brands',
  channels = 'channels',
}

const nodeHasDeepAccess = (node: any, keycloak: any): boolean => {
  if (hasUserAccess(node.address, keycloak)) {
    return true;
  }

  let hasDeepAccess = false;

  Object.values(DataNodeTypes).forEach((nodeType) => {
    let nodesMap = node[nodeType];

    for (const key in nodesMap) {
      const childNode = nodesMap[key];
      if (nodeHasDeepAccess(childNode, keycloak)) {
        hasDeepAccess = true;
      }
    }
  });

  return hasDeepAccess;
};

export const filterDataByHasAccess = (data: any, keycloak: any) => {
  const result = cloneDeep(data);

  const innerFilterDataByHasAccess = (node: any) => {
    Object.values(DataNodeTypes).forEach((nodeType) => {
      let nodesMap = node[nodeType];

      for (const key in nodesMap) {
        const childNode = nodesMap[key];
        const hasAccess = nodeHasDeepAccess(childNode, keycloak);
        if (hasAccess) {
          innerFilterDataByHasAccess(childNode);
        } else {
          delete nodesMap[key];
        }
      }
    });
  };

  innerFilterDataByHasAccess(result);

  return result;
};
