import packageJson from '../../package.json';

export const version = packageJson.version;

export const logVersion = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `rabbit-hole-ui: %c ${version} `,
      'background: #030530; color: #bada55; font-weight: bold'
    );
  }
};
