import { flow, getRoot, Instance, types } from 'mobx-state-tree';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { save, load } from '@idealmediaworks/lookingglass-ui-localstorage';
import Brand, { IBrand } from '../brand';

const Region = types.maybeNull(
  types
    .model({
      isLoading: types.optional(types.boolean, true),
      hasError: types.optional(types.boolean, false),
      messageType: types.maybeNull(types.string),

      id: types.string,
      name: types.maybeNull(types.string),
      address: types.maybeNull(types.string),
      brands: types.array(Brand),

      selectedBrandId: types.maybeNull(types.string),
    })
    .views((self) => ({
      get selectedBrand(): IBrand | null | undefined {
        if (!self.selectedBrandId) return null;

        return self.brands.find((brand) => brand?.id === self.selectedBrandId);
      },
    }))
    .actions((self) => {
      const updateMetadata = flow(function* () {
        const { hierarchy } = getRoot(self);

        if (self.selectedBrand?.selectedChannel) {
          self.selectedBrand.selectedChannel.setIsRefreshing(true);
        }

        if (self.selectedBrand) {
          yield self.selectedBrand.updateChannelsMetadata();
          self.selectedBrand.setIsLoading(false);

          if (hierarchy.enableStream) {
            yield self.selectedBrand.updateSchedulesData();

            hierarchy.unsubscribeAllStreams();
            self.selectedBrand.subscribeBrandToJobsEventSource();
          }
        }
      });

      return { updateMetadata };
    })
    .actions((self) => {
      const setIsLoading = (value: boolean) => {
        self.isLoading = value;
      };

      const changeSelectedBrand = (id: string | null) => {
        if (!id) return;
        save('brand-id', id);

        self.selectedBrandId = id;
        self.updateMetadata();

        const channels = self.selectedBrand?.channels;
        const selectedChannelId = self.selectedBrand?.selectedChannelId;

        if (channels && channels.length > 0) {
          const localChannelId = load('channel-id');
          const channedId =
            selectedChannelId || localChannelId || channels[0].id;
          self.selectedBrand?.changeSelectedChannel(channedId);
        }
      };

      return {
        setIsLoading,
        changeSelectedBrand,
      };
    })
);

export default Region;

export type IRegion = Instance<typeof Region>;
