import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Panel } from '@idealmediaworks/lookingglass-ui-framework';
import ChannelMonths from '../containers/channelsArea/channelMonths/ChannelMonths';

const PanelMonths: React.FC = (): JSX.Element => {
  return (
    <Panel>
      <ChannelMonths />
    </Panel>
  );
};

export default PanelMonths;
