import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Calendar } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  date: Date;
  handleRenderDay: (
    dayDate: MaterialUiPickersDate,
    calendarSelectedDate: MaterialUiPickersDate,
    isInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => JSX.Element;
}

const ChannelMonth: React.FC<Props> = ({
  date,
  handleRenderDay,
}): JSX.Element => {
  return (
    <div className="calendar">
      <Paper elevation={0} className="calendar-paper">
        <Calendar
          date={date}
          allowKeyboardControl={false}
          onChange={(e) => {}}
          renderDay={handleRenderDay}
        />
      </Paper>
    </div>
  );
};

export default ChannelMonth;
