// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import {
  apiMadHatter,
  apiMadHatterStream,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
} from '@idealmediaworks/lookingglass-ui-framework';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { save, load } from '@idealmediaworks/lookingglass-ui-localstorage';
import { toJS } from 'mobx';
import { flow, getRoot, Instance, types } from 'mobx-state-tree';
import Channel, { IChannel } from '../channel';
import { getChannelsMetadata } from '../../utils';

const Brand = types
  .model({
    isLoading: types.optional(types.boolean, true),
    hasError: types.optional(types.boolean, false),
    messageType: types.maybeNull(types.string),

    id: types.string,
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    channels: types.array(Channel),

    selectedChannelId: types.maybeNull(types.string),
    jobsSubscription: types.optional(types.maybeNull(types.frozen()), null),
  })
  .views((self) => ({
    get selectedChannel(): IChannel | null | undefined {
      if (!self.selectedChannelId) return null;

      return self.channels.find(
        (channel) => channel?.id === self.selectedChannelId
      );
    },
  }))
  .actions((self) => {
    const setIsLoading = (value: boolean) => {
      self.isLoading = value;
    };

    const changeSelectedChannel = (id: string) => {
      if (!id) return;
      save('channel-id', id);

      const { hierarchy } = getRoot(self);

      self.selectedChannelId = id;

      if (hierarchy.enableAsruns) {
        self.selectedChannel?.getAsruns();
      }
    };

    const channelQueryString = (channel: any) => {
      return `data=${encodeURIComponent(
        JSON.stringify({
          scope: channel.address,
          prefix: channel.channelMetadata?.inputfilePrefix || '',
          dateNotation: channel.channelMetadata?.inputFileDateNotation,
          days: channel.channelMetadata?.daysForAdvancedSchedule,
          timeZone: channel.channelMetadata?.timezone,
        })
      )}`;
    };

    const generateQueryString = () => {
      return self.channels.map(channelQueryString);
    };

    return {
      setIsLoading,
      changeSelectedChannel,
      generateQueryString,
      channelQueryString,
    };
  })
  .actions((self) => {
    const updateChannelsMetadata = flow(function* () {
      const channelsMetadata: any = yield getChannelsMetadata(self.address);

      self.channels.forEach((channel: IChannel) => {
        if (channelsMetadata && channel.name) {
          const currentChannel = channelsMetadata[channel.name];

          if (currentChannel) {
            channel.updateMetadata(currentChannel.metadata);
          }
        }
      });
    });

    const updateSchedulesDataAll = flow(function* () {
      self.channels.forEach((channel) => {
        channel.generateEmptyChannelDays();
      });

      try {
        const query = self.generateQueryString();
        const { data } = yield apiMadHatter().get(
          `/search/all?${query.join('&')}`
        );

        self.channels.forEach((channel) => {
          channel.updateAllJobsData(data);
        });
      } catch (error) {
        console.error('updateSchedulesData', error);
      }
    });

    const updateSchedulesData = flow(function* () {
      self.channels.forEach((channel) => {
        channel.generateEmptyChannelDays();
        channel.setIsLoading(false);
        channel.setIsRefreshing(true);
      });

      for (const channel of self.channels) {
        yield apiMadHatter()
          .get(`/search/all?${self.channelQueryString(channel)}`)
          .then((res: any) => {
            channel.updateAllJobsData(res.data);
            channel.setIsRefreshing(false);
          });
      }
    });

    const subscribeBrandToJobsEventSource = () => {
      const query = self.generateQueryString();

      self.jobsSubscription = apiMadHatterStream(
        '/search/stream',
        query.join('&')
      );
      self.jobsSubscription.onmessage = function (event: { data: string }) {
        const job: any = JSON.parse(event.data);

        self.channels.forEach((channel) => {
          if (channel.address === job.scope) {
            channel.channelsDays.forEach((day) => {
              if (day.expectedFileDate === job.text) {
                day.updateJobSignal(job.jobSignal);
                day.updateFileName(job.filename);
              }
            });
          }
        });

        console.log('EventSource Message:', job);
      };
      self.jobsSubscription.onerror = function (e: any) {
        self.setIsLoading(false);
        self.channels.forEach((channel) => {
          channel.setIsLoading(false);
        });
        console.error('sse.onerror', toJS(self.jobsSubscription), e);
      };
    };

    const unsubscribeBrandToJobsEventSource = () => {
      if (self.jobsSubscription) {
        self.jobsSubscription.close();
      }
    };

    return {
      updateChannelsMetadata,
      updateSchedulesData,
      subscribeBrandToJobsEventSource,
      unsubscribeBrandToJobsEventSource,
    };
  });

export default Brand;

export type IBrand = Instance<typeof Brand>;
