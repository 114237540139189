import React, { useMemo, useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Panel } from '@idealmediaworks/lookingglass-ui-framework';
import { observer } from 'mobx-react';
import { useHierarchyStore } from '../models';
import { IChannel } from '../models/hierarchy/channel';
import Message from '../components/message/Message';
import VideoJS from '../components/videojs/VideoJs';

const PanelBrands: React.FC = (): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();

  const selectedChannelId = selectedRegion?.selectedBrand?.selectedChannel?.id;

  const selectedChannel = useMemo(() => {
    if (!selectedChannelId) {
      return null;
    }

    return selectedRegion?.selectedBrand?.channels.find((channel: IChannel) => channel.id === selectedChannelId);
  }, [selectedChannelId]);

  const liveStreamURL = selectedChannel?.channelMetadata?.liveStreamURL;

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: liveStreamURL,
      type: 'application/x-mpegURL'
    }]
  };

  const isValidURL = useCallback(() => {
    if (!liveStreamURL) {
      return false;
    }

    if (!liveStreamURL.includes('http')) {
      return false;
    }

    return true;
  }, [liveStreamURL]);

  if (!selectedRegion?.id) {
    return <Message type="NO_REGION_SELECTED" isFullPage />;
  }

  if (!selectedRegion.selectedBrand?.id) {
    return <Message type="NO_BRAND_SELECTED" isFullPage />;
  }

  if (selectedRegion.selectedBrand.channels.length === 0) {
    return <Message type="NO_CHANNELS" isFullPage />;
  }

  if (!selectedChannel) {
    return <Message type="NO_CHANNEL_SELECTED" isFullPage />;
  }

  if (!liveStreamURL || !liveStreamURL.length) {
    return <Message type="NO_STREAM_URL" isFullPage />;
  }

  if (!isValidURL()) {
    return <Message type="INVALID_STREAM_URL" isFullPage />;
  }

  return (
    <Panel>
      <VideoJS options={videoJsOptions} />
    </Panel>
  );
};

export default observer(PanelBrands);
