// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { apiObject } from '@idealmediaworks/lookingglass-ui-framework';
import { flow, getParent, Instance, SnapshotOut, types } from 'mobx-state-tree';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import Channel from '../channel';
import { showErrorNotification, showSuccessNotification } from '../../../utils';
import { scheduleGroup } from '../../../config';
import JobSignal, { IJobSignal } from './jobSignal';

const ChannelDay = types
  .model({
    date: types.frozen(),
    labelDate: types.string,
    jobSignal: types.maybeNull(JobSignal),
    expectedFileName: types.maybeNull(types.string),
    expectedFileDate: types.maybeNull(types.string),
    fileName: types.maybeNull(types.string),
    isLoading: types.optional(types.maybeNull(types.boolean), false),
    isUploading: types.optional(types.maybeNull(types.boolean), false),

    hasErrors: types.optional(types.boolean, false),
    errors: types.model({
      scheduleParserID: types.optional(types.boolean, false),
      inputFileDateNotation: types.optional(types.boolean, false),
    }),
  })
  .actions((self) => {
    const updateJobSignal = (jobSignal: IJobSignal) => {
      self.jobSignal = jobSignal;
    };

    const updateFileName = (fileName: string) => {
      self.fileName = fileName;
    };

    const setIsLoading = (value: boolean) => {
      self.isLoading = value;
    };

    const setIsUploading = (value: boolean) => {
      self.isUploading = value;
    };

    return {
      updateJobSignal,
      updateFileName,
      setIsLoading,
      setIsUploading,
    };
  })
  .actions((self) => {
    const generateExpectedFileName = () => {
      const { channelMetadata } = getParent<typeof Channel>(getParent(self));
      let prefix = '';
      let date = '';
      let ext = '';

      if (channelMetadata) {
        const { inputfilePrefix, inputFileDateNotation, inputFileExtension } =
          channelMetadata;

        if (inputfilePrefix) {
          prefix = inputfilePrefix;
        }

        if (inputFileDateNotation) {
          date = format(self.date, inputFileDateNotation);
          self.expectedFileDate = date;
        }

        if (inputFileExtension) {
          ext = `.${inputFileExtension}`;
        }
      }

      self.expectedFileName = `${prefix}${date}${ext}`;
    };

    const checkForErrors = () => {
      const { channelMetadata } = getParent<typeof Channel>(getParent(self));
      if (channelMetadata) {
        const { inputFileDateNotation, scheduleParserID } = channelMetadata;

        if (!inputFileDateNotation) {
          self.errors.inputFileDateNotation = true;
          self.hasErrors = true;
        }

        if (!scheduleParserID) {
          self.errors.scheduleParserID = true;
          self.hasErrors = true;
        }
      }
    };

    const isChannelDayValidToUploadSameDay = (): boolean => {
      const { channelMetadata } = getParent<typeof Channel>(getParent(self));

      if (!isToday(self.date)) {
        return true;
      }

      if (channelMetadata) {
        const { uploadSameDaySchedule } = channelMetadata;

        if (uploadSameDaySchedule) {
          return true;
        }
      }

      return false;
    };

    const hasExpectedFileNameStructure = (fileName: string): boolean => {
      const { channelMetadata } = getParent<typeof Channel>(getParent(self));

      if (self.expectedFileDate) {
        if (channelMetadata?.inputfilePrefix) {
          const prefixIndex = fileName
            .toUpperCase()
            .indexOf(channelMetadata.inputfilePrefix.toUpperCase());
          const dateIndex = fileName.indexOf(self.expectedFileDate);

          if (
            prefixIndex !== -1 &&
            dateIndex !== -1 &&
            prefixIndex < dateIndex
          ) {
            return true;
          }
        } else {
          const dateIndex = fileName.indexOf(self.expectedFileDate);

          if (dateIndex !== -1) {
            return true;
          }
        }
      }

      return false;
    };

    const saveScheduleFile = flow(function* (
      channelAddress: string,
      file: File
    ) {
      self.setIsUploading(true);
      self.setIsLoading(true);

      const address = `${channelAddress}.${scheduleGroup}`;
      const newFileName = file.name.toLowerCase();

      const formData = new FormData();
      const config = {
        params: {
          nodeAddress: address,
        },
        headers: {
          'content-Type': 'multipart/form-data',
        },
      };
      formData.append('file', file, newFileName);
      formData.append('type', 'object');
      formData.append('node-address', address);

      try {
        yield apiObject().post('saveObjectAndStartProcess', formData, config);

        showSuccessNotification(`Uploaded schedule to ${address}`);

        self.setIsUploading(false);
        self.setIsLoading(false);
      } catch (err) {
        showErrorNotification(err.message);
        self.setIsUploading(false);
        self.setIsLoading(false);
      }
    });

    return {
      generateExpectedFileName,
      checkForErrors,
      isChannelDayValidToUploadSameDay,
      hasExpectedFileNameStructure,
      saveScheduleFile,
    };
  });

export default ChannelDay;

export type IChannelDay = Instance<typeof ChannelDay>;
export type IChannelDayIn = SnapshotOut<typeof ChannelDay>;
