import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Panel } from '@idealmediaworks/lookingglass-ui-framework';
import BrandsArea from '../containers/brandsArea/BrandsArea';

const PanelBrands: React.FC = (): JSX.Element => {
  return (
    <Panel>
      <BrandsArea />
    </Panel>
  );
};

export default PanelBrands;
