import React from 'react';
import { observer } from 'mobx-react';
import { useHierarchyStore } from '../../../models';
import { IChannel } from '../../../models/hierarchy/channel';
import ChannelHeatmap from '../ChannelHeatmap/ChannelHeatmap';
import Grid from '../../../components/grid/Grid';
import Preloader from '../../../components/preloader/Preloader';
import Message from '../../../components/message/Message';

const ChannelsMaps: React.FC = (): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();

  const selectedChannelId = selectedRegion?.selectedBrand?.selectedChannel?.id;

  const handleClick = (id: string) => {
    selectedRegion?.selectedBrand?.changeSelectedChannel(id);
  };

  if (!selectedRegion?.id) {
    return <Message type="NO_REGION_SELECTED" isFullPage />;
  }

  if (!selectedRegion.selectedBrand?.id) {
    return <Message type="NO_BRAND_SELECTED" isFullPage />;
  }

  if (selectedRegion.selectedBrand.isLoading) {
    return <Preloader isFullPage />;
  }

  if (selectedRegion.selectedBrand.channels.length === 0) {
    return <Message type="NO_CHANNELS" isFullPage />;
  }

  // if (selectedRegion.selectedBrand.channels[0].channelsDays.length === 0) {
  //   return <Message type="NO_CHANNELS_SETUP" isFullPage />;
  // }

  return (
    <div style={{ padding: 6 }}>
      <Grid>
        {selectedRegion?.selectedBrand?.channels.map(
          (channel: IChannel, index: number) => {
            // if (channel?.channelsDays.length === 0) {
            //   return null;
            // }

            return (
              <ChannelHeatmap
                key={index}
                channel={channel}
                selected={channel.id === selectedChannelId}
                onClick={() => handleClick(channel.id)}
              />
            );
          }
        )}
      </Grid>
    </div>
  );
};

export default observer(ChannelsMaps);
