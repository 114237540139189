import { Instance, types } from 'mobx-state-tree';

const JobSignal = types.model({
  jobId: types.string,
  jobStatus: types.string,
  jobClass: types.string,
  jobType: types.string,
  shortMessage: types.maybeNull(types.string),
});

export default JobSignal;
export type IJobSignal = Instance<typeof JobSignal>;
