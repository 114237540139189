export const hasUserAccess = (address, keycloak) => {
  const assignedGroups = keycloak.tokenParsed.Groups;

  function subaddress(addr) {
    const result = [];
    const array = addr.split('.');

    do {
      result.push(`/${array.join('/')}`);
      array.pop();
    } while (array.length > 0);

    return result;
  }

  function checkIfArrayHasItemInArray(arr1, arr2) {
    return arr1.some((item) => arr2.includes(item));
  }

  if (!assignedGroups) return false;

  if (checkIfArrayHasItemInArray(subaddress(address), assignedGroups)) {
    return true;
  }

  return false;
};
