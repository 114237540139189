import React from 'react';
import { observer } from 'mobx-react';
// import { CircularProgress } from '@material-ui/core';
import { useHierarchyStore } from '../../models';
import TabList from '../../components/tabsList';
import Preloader from '../../components/preloader/Preloader';
import Message from '../../components/message/Message';

const ChannelsArea: React.FC = ({ children }): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();

  const handleChange = (id: string) => {
    selectedRegion?.selectedBrand?.changeSelectedChannel(id);
  };

  if (!selectedRegion?.id) {
    return <Message type="NO_REGION_SELECTED" isFullPage />;
  }

  if (!selectedRegion.selectedBrand?.id) {
    return <Message type="NO_BRAND_SELECTED" isFullPage />;
  }

  if (selectedRegion.selectedBrand.isLoading) {
    return <Preloader isFullPage />;
  }

  if (selectedRegion.selectedBrand.channels.length === 0) {
    return <Message type="NO_CHANNELS" isFullPage />;
  }

  // if (!selectedRegion.selectedBrand.selectedChannel?.id) {
  //   return <>No channel selected</>;
  // }

  // if (selectedRegion?.selectedBrand?.selectedChannel?.isLoading) {
  //   return <Preloader isFullPage />;
  // }

  const { selectedChannel, channels } = selectedRegion.selectedBrand;

  return (
    <TabList
      selectedItem={selectedChannel}
      list={channels}
      changeSelectedItem={handleChange}
    />
  );
};

export default observer(ChannelsArea);
