import React from 'react';
import { useKeycloak } from '@react-keycloak/web';

const NoAccessPage = () => {
    const { keycloak } = useKeycloak();
  
return (
  <div style={{color: '#D9D9D9'}}>
    <h1>You don't have access, please contact system administrator to get access.</h1>
    <hr />
    {!!keycloak?.authenticated && (
    <button type="button" onClick={() => keycloak.logout()}>
      Logout
    </button>
      )}
  </div>
  );
};

export default NoAccessPage;