import PanelRegions from './PanelRegions';
import PanelBrands from './PanelBrands';
import PanelChannels from './PanelChannels';
import PanelHeatmaps from './PanelHeatmaps';
import PanelDays from './PanelDays';
import PanelMonths from './PanelMonths';
import PanelStream from './PanelStream';

const panels = {
  'panel-regions': {
    id: 'panel-regions',
    title: 'Regions',
    component: PanelRegions,
  },
  'panel-brands': {
    id: 'panel-brands',
    title: 'Brands',
    component: PanelBrands,
  },
  'panel-heatmaps': {
    id: 'panel-heatmaps',
    title: 'Heatmaps',
    component: PanelHeatmaps,
  },
  'panel-channels': {
    id: 'panel-channels',
    title: 'Channels',
    component: PanelChannels,
  },
  'panel-days': {
    id: 'panel-days',
    title: 'Channel Days',
    component: PanelDays,
  },
  'panel-months': {
    id: 'panel-months',
    title: 'Channel Months',
    component: PanelMonths,
  },
  'panel-stream': {
    id: 'panel-stream',
    title: 'Channel Stream',
    component: PanelStream,
  },
};

export default panels;
