// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { apiNode } from '@idealmediaworks/lookingglass-ui-framework';
import { cast, flow, Instance, types } from 'mobx-state-tree';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import sub from 'date-fns/sub';
import differenceInMonths from 'date-fns/differenceInMonths';
import addMonths from 'date-fns/addMonths';
import startOfMonth from 'date-fns/startOfMonth';
import { startOfDay } from 'date-fns';
import ChannelDay, { IChannelDayIn } from '../channelDay';
import { showErrorNotification } from '../../../utils';

const Channel = types
  .model({
    isLoading: types.optional(types.boolean, true),
    isRefreshing: types.optional(types.boolean, false),
    hasError: types.optional(types.boolean, false),
    messageType: types.maybeNull(types.string),

    id: types.identifier,
    name: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    objectGroups: types.model({
      asruns: types.maybeNull(
        types.model({
          id: types.string,
        })
      ),
    }),
    channelMetadata: types.maybeNull(
      types.model({
        enableAsrun: types.maybeNull(types.optional(types.boolean, false)),
        asrunDays: types.number,
        daysForAdvancedSchedule: types.maybeNull(types.number),
        scheduleParserID: types.maybeNull(types.string),
        inputfilePrefix: types.string,
        inputFileExtension: types.maybeNull(types.string),
        inputFileDateNotation: types.maybeNull(types.string),
        asrunFileDateNotation: types.maybeNull(types.string),
        uploadSameDaySchedule: types.maybeNull(types.boolean),
        liveStreamURL: types.maybeNull(types.string),
        timezone: types.maybeNull(types.string),
      })
    ),
    channelsDays: types.array(ChannelDay),
    jobsSubscription: types.optional(types.maybeNull(types.frozen()), null),
    asRunDays: types.maybeNull(
      types.map(
        types.model({
          isAvailable: types.boolean,
          fileName: types.string,
          aliceName: types.string,
          creationDate: types.string,
        })
      )
    ),
  })
  .views((self) => ({
    get firstDate(): Date {
      const asrunDays = self.channelMetadata?.asrunDays;
      const now = new Date();

      // Limit asrunDays to 180 days
      const maxAsrunDays = Math.min(asrunDays as number, 180);
      const firstDate = startOfDay(sub(now, { days: maxAsrunDays }));

      return firstDate;
    },
  }))
  .views((self) => ({
    get generatedMonths(): string[] {
      const datesArray = [];
      const now = new Date();
      const firstDateStartOfMonth = startOfMonth(self.firstDate);
      const monthsCount = differenceInMonths(now, firstDateStartOfMonth);

      for (let i = 0; i <= monthsCount; i++) {
        datesArray.push(
          format(addMonths(firstDateStartOfMonth, i), 'yyyy-MM-dd')
        );
      }

      return datesArray;
    },
  }))
  .actions((self) => {
    const setIsLoading = (value: boolean) => {
      self.isLoading = value;
    };

    const setIsRefreshing = (value: boolean) => {
      self.isRefreshing = value;
    };

    return {
      setIsLoading,
      setIsRefreshing,
    };
  })
  .actions((self) => {
    const generateEmptyChannelDays = () => {
      if (self.channelMetadata?.daysForAdvancedSchedule) {
        let newChannelsDays: IChannelDayIn[] = [];
        let date = new Date();

        for (let i = 0; i < self.channelMetadata.daysForAdvancedSchedule; i++) {
          let labelDate = format(date, 'dd/MM/yyyy');

          let newChannelDay: IChannelDayIn = ChannelDay.create({
            isLoading: false,
            jobSignal: null,
            date,
            expectedFileName: null,
            labelDate,
            fileName: null,
            hasErrors: false,
            errors: {},
          });

          newChannelsDays.push(newChannelDay);

          date = addDays(date, 1);
        }

        self.channelsDays = cast(newChannelsDays);

        self.channelsDays.forEach((day) => {
          day.generateExpectedFileName();
          day.checkForErrors();
        });
      }
    };

    const getAsruns = flow(function* () {
      self.setIsLoading(false);
      self.setIsRefreshing(true);

      if (!self.objectGroups.asruns) {
        self.setIsRefreshing(false);

        return;
      }

      try {
        const { data } = yield apiNode().get(
          `/${self.objectGroups.asruns.id}/objects`
        );

        const formatedData = data.reduce((acc: any, val: any) => {
          acc[`${val.aliceName}`] = {
            isAvailable: true,
            fileName: val.objectName,
            aliceName: val.aliceName,
            creationDate: val.creationDate,
          };

          return acc;
        }, {});

        self.asRunDays = formatedData;
        self.setIsRefreshing(false);
      } catch (err) {
        // console.error('error: ', err.message);
        // showErrorNotification(err.message);
        self.setIsRefreshing(false);
      }
    });

    return {
      generateEmptyChannelDays,
      getAsruns,
    };
  })
  .actions((self) => {
    const updateMetadata = (metadata: any) => {
      const inputFilePrefix =
        typeof metadata.inputfilePrefix === 'string'
          ? metadata.inputfilePrefix
          : '';
      const inputFilePrefixLowerCase = inputFilePrefix.toLowerCase();

      self.channelMetadata = {
        ...metadata,
        inputfilePrefix: inputFilePrefixLowerCase,
      };
    };

    const updateAllJobsData = (jobList: []) => {
      if (jobList && jobList.length > 0) {
        jobList.forEach((job: any) => {
          if (self.address === job.scope) {
            const day = self.channelsDays.find(
              (chanDay: IChannelDayIn): any => {
                return chanDay.expectedFileDate === job.text;
              }
            );

            day?.updateJobSignal(job.jobSignal);
            day?.updateFileName(job.filename);
          }
        });
      }

      // self.setIsLoading(false);
      // self.setIsRefreshing(false);
    };

    const isReadyForUpload = () => {
      if (!self.channelMetadata?.inputFileDateNotation) {
        self.setIsLoading(false);
        self.setIsRefreshing(false);

        return false;
      }

      return true;
    };

    return { updateMetadata, updateAllJobsData, isReadyForUpload };
  });

export default Channel;

export type IChannel = Instance<typeof Channel>;
