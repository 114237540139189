import React from 'react';
import { observer } from 'mobx-react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import format from 'date-fns/format';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { IChannel } from '../../../models/hierarchy/channel';
import { JobStatusTypes } from '../../../models/hierarchy';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4,
    background: '#4c4f56',
    padding: 6,
    width: theme.spacing(17.25),

    boxSizing: 'border-box',
    borderRadius: 2,
    cursor: 'pointer',
    color: '#000',

    '&$selected': {
      background: '#c5c9cd',

      '& $title': {
        color: theme.palette.text.primary,
        fontWeight: 'bold',
      },

      '& $statusBox': {
        background: '#c5c9cd',

        '&$complete': {
          background: '#87ab77',
        },

        '&$error': {
          background: '#cc2e2b',
        },

        '&$inProgress': {
          background: '#347de2',
        },

        '&$warning': {
          background: '#e48b06',
        },
      },
    },
  },

  title: {
    position: 'relative',
    marginBottom: 5,
    textTransform: 'uppercase',
    fontSize: '.7em',
    letterSpacing: '2px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRadius: 2,
  },

  preloader: { position: 'absolute', left: 0, bottom: 0, width: '100%' },

  statusWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  statusBox: {
    background: theme.palette.background.paper,
    width: theme.spacing(2),
    height: theme.spacing(2),
    // boxSizing: 'border-box',
    border: '1px solid #000',
    borderRadius: 2,
    margin: 1,

    '&$complete': {
      background: '#87ab77',
    },

    '&$error': {
      background: '#cc2e2b',
    },

    '&$inProgress': {
      background: '#347de2',
    },

    '&$warning': {
      background: '#e48b06',
    },
  },

  message: {
    textAlign: 'center',
    marginBottom: -5,
    flexGrow: 1,
  },

  selected: {},
  complete: {},
  error: {},
  inProgress: {},
  warning: {},
}));

interface Props {
  channel: IChannel;
  selected: boolean;
  onClick: () => void;
}

const ChannelMap: React.FC<Props> = observer((props) => {
  const { channel, selected, onClick } = props;
  const styles = useStyles();

  if (channel.channelsDays.length === 0) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, {
        [styles.selected]: selected,
      })}
      onClick={onClick}
    >
      {channel.name && (
        <Tooltip title={channel.name}>
          <div className={styles.title}>
            {channel.name}{' '}
            {channel.isRefreshing && (
              <div className={styles.preloader}>
                <LinearProgress style={{ height: 2 }} />
              </div>
            )}
          </div>
        </Tooltip>
      )}

      <div className={styles.statusWrapper}>
        {channel.channelsDays.map((day, index: number) => {
          const jobStatus = day?.jobSignal?.jobStatus;

          return (
            <Tooltip
              key={index}
              title={`Date: ${format(day.date, 'dd/MM/yyyy')}`}
              aria-label="add"
            >
              <div
                className={classNames([
                  styles.statusBox,
                  {
                    [styles.complete]: jobStatus === JobStatusTypes.complete,
                    [styles.error]: jobStatus === JobStatusTypes.error,
                    [styles.inProgress]:
                      jobStatus === JobStatusTypes.inProgress,
                    [styles.warning]:
                      jobStatus === JobStatusTypes.completedWithWarnings,
                  },
                ])}
              />
            </Tooltip>
          );
        })}
        {channel.channelsDays.length === 0 && (
          <div className={styles.message}>
            <ErrorOutlineIcon fontSize="small" />
          </div>
        )}
      </div>
    </div>
  );
});

export default ChannelMap;
