import React from 'react';
import Box from '@material-ui/core/Box';

import packageJson from '../../../../package.json';

const AboutModal = () => {

  return (
    <Box pl={2} pr={2} pb={1} style={{ width: 300 }}>
      <h3>RabbitHole versions</h3>
      <p pb={1}>{`rabbit-hole-ui: ${packageJson.version}`}</p>
      <p pb={1}>{`lookingglass-ui-framework: ${packageJson.dependencies['@idealmediaworks/lookingglass-ui-framework']}`}</p>
    </Box>
  );
};

export default AboutModal;
