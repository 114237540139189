import { cast, flow, Instance, types } from 'mobx-state-tree';
// import { toJS } from 'mobx';
// import cloneDeep from 'lodash.clonedeep';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { apiHierarchy } from '@idealmediaworks/lookingglass-ui-framework';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { save, load } from '@idealmediaworks/lookingglass-ui-localstorage';
import Region, { IRegion } from './region';
import { extractBrands } from '../utils';
// import Brand, { IBrand } from './brand';
import { filterDataByHasAccess, sortByKey } from '../../utils';
// import { ContactsTwoTone } from '@material-ui/icons';

export enum JobStatusTypes {
  complete = 'COMPLETE',
  inProgress = 'IN_PROGRESS',
  warning = 'WARNING',
  completedWithWarnings = 'COMPLETED_WITH_WARNINGS',
  error = 'ERROR',
}

export enum MessageTypes {
  noPlatform = 'NO_PLATFORM',
  noAccessToRegions = 'NO_ACCESS_TO_REGIONS',
  noRegions = 'NO_REGIONS',
}

export enum DebugMessageTypes {
  noPlatform = 'No platform defined',
  noAccessToRegions = 'No regions to display. Reason: Hierarchy contains regions, but you don`t have access to any of them',
  noRegions = 'No regions to display. Reason: Hierarchy contains no regions',
  asrunsDaysExeedsLimit = 'Showing last 180 As-Runs days',
}

export const hierarchyInitialState = {
  isLoading: true,
  hasError: false,
  messageType: null,
  enableAsruns: false,
  enableStream: false,

  data: null,

  regions: [],
  brands: [],

  selectedRegionId: null,
  selectedBrandId: null,
  selectedChannelId: null,
};

const Hierarchy = types
  .model({
    isLoading: types.boolean,
    hasError: types.boolean,
    messageType: types.maybeNull(types.string),
    enableAsruns: types.optional(types.boolean, false),
    enableStream: types.optional(types.boolean, false),

    data: types.maybeNull(types.frozen()),

    regions: types.array(Region),
    selectedRegionId: types.maybeNull(types.string),
  })
  .views((self) => ({
    get selectedRegion(): IRegion | undefined {
      if (!self.selectedRegionId) return null;

      return self.regions.find(
        (region) => region?.id === self.selectedRegionId
      );
    },
  }))
  .actions((self) => {
    const extractData = (data: any, keycloak: any) => {
      const regions: IRegion[] = [];
      const { regions: filteredRegions } = filterDataByHasAccess(
        data,
        keycloak
      );

      for (const property in filteredRegions) {
        const dataRegion = filteredRegions[property];
        const brands = extractBrands(dataRegion);

        const region = {
          name: dataRegion.name,
          address: dataRegion.address,
          id: dataRegion.id,
          brands,
        };

        regions.push(cast(region));
      }

      sortByKey(regions, 'name');

      return regions;
    };

    const unsubscribeAllStreams = () => {
      self.regions.forEach((region) => {
        region?.brands.forEach((brand) => {
          brand.unsubscribeBrandToJobsEventSource();
        });
      });
    };

    return {
      extractData,
      unsubscribeAllStreams,
    };
  })
  .actions((self) => {
    const changeSelectedRegion = (id: string | undefined | null) => {
      if (!id) return;
      save('region-id', id);

      self.selectedRegionId = id;
      self.selectedRegion?.setIsLoading(false);

      const brands = self.selectedRegion?.brands;
      const selectedBrandId = self.selectedRegion?.selectedBrandId;

      if (selectedBrandId) {
        self.selectedRegion?.changeSelectedBrand(null);
      }

      if (brands && brands.length > 0) {
        const localBrandId = load('brand-id');
        const brandId = selectedBrandId || localBrandId || brands[0].id;
        self.selectedRegion?.changeSelectedBrand(brandId);
      }
    };

    return {
      changeSelectedRegion,
    };
  })
  .actions((self) => {
    const getHierarchyData = flow(function* (keycloak) {
      self.isLoading = true;
      try {
        const { data } = yield apiHierarchy().get('/platform');
        const regions = self.extractData(data, keycloak);
        const isEmpty: boolean = Object.keys(data.regions).length === 0;

        self.data = data;

        if (isEmpty) {
          self.messageType = MessageTypes.noRegions;
          self.hasError = true;
          console.warn(DebugMessageTypes.noRegions);

          return;
        }

        if (!isEmpty && regions.length === 0) {
          self.messageType = MessageTypes.noAccessToRegions;
          self.hasError = true;
          console.warn(DebugMessageTypes.noAccessToRegions);

          return;
        }

        self.regions.replace(regions);
      } catch (err) {
        self.messageType = MessageTypes.noPlatform;
        self.hasError = true;
        console.warn(DebugMessageTypes.noPlatform);
        console.error(err);
      } finally {
        self.isLoading = false;
      }
    });

    const initSchedules = () => {
      const localRegionId = load('region-id');
      const id = localRegionId || self.selectedRegionId || self.regions[0]?.id;

      self.enableAsruns = false;
      self.enableStream = true;
      self.changeSelectedRegion(id);
    };

    const initAsRuns = () => {
      self.unsubscribeAllStreams();
      self.enableAsruns = true;
      self.enableStream = false;
      self.changeSelectedRegion(self.selectedRegionId);
    };

    return { getHierarchyData, initSchedules, initAsRuns };
  });

export default Hierarchy;

export type IHierarchy = Instance<typeof Hierarchy>;
