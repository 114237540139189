import React from 'react';
import { observer } from 'mobx-react';
import { useHierarchyStore } from '../../models';
import TabList from '../../components/tabsList';
// import Preloader from '../../components/preloader/Preloader';

const RegionArea: React.FC = ({ children }): JSX.Element => {
  const { selectedRegion, changeSelectedRegion, regions } = useHierarchyStore();

  const handleChange = (id: string) => {
    changeSelectedRegion(id);
  };

  return (
    <TabList
      selectedItem={selectedRegion}
      list={regions}
      changeSelectedItem={handleChange}
    />
  );
};

export default observer(RegionArea);
