import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { Panel } from '@idealmediaworks/lookingglass-ui-framework';
import ChannelsHeatmaps from '../containers/brandsArea/ChannelsHeatmaps/ChannelsHeatmaps';

const PanelHeatmaps: React.FC = (): JSX.Element => {
  return (
    <Panel>
      <ChannelsHeatmaps />
    </Panel>
  );
};

export default PanelHeatmaps;
