import React, { useContext } from 'react';
import { LayoutContext } from '@idealmediaworks/lookingglass-ui-framework';
import { StickyMenu, StickyItem, StickyThemeToggle } from '@idealmediaworks/lookingglass-ui-kit';

import InfoIcon from '@material-ui/icons/Info';

import AboutModal from './AboutModal';

const SideMenu = () => {
  const { theme } = useContext(LayoutContext);

  return (
    <StickyMenu>
      <StickyItem icon={<InfoIcon color="inherit" />}>
        <AboutModal />
      </StickyItem>
      <StickyThemeToggle toggleTheme={theme.toggleTheme} />
    </StickyMenu>
  );
};

export default SideMenu;
