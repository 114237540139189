// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { apiHierarchy, apiMadHatter } from '@idealmediaworks/lookingglass-ui-framework';
import isEmpty from 'lodash.isempty';
import { sortByKey } from '../utils';

const extractChannels = (dataBrand: any): any[] => {
  let result: any[] = [];

  const innerExtractChannels = (data: any): void => {
    const { channels, brands } = data;

    for (const property in channels) {
      const channel = channels[property];
      const objectGroups = {
        asruns: channel.objectGroups?.asruns
          ? {
            id: channel.objectGroups?.asruns?.id,
          }
          : null,
      };

      result.push({
        name: channel.name,
        id: channel.id,
        address: channel.address,
        objectGroups,
      });

      if (!isEmpty(channel.channels)) {
        innerExtractChannels(channel);
      }
    }

    if (!isEmpty(brands)) {
      for (const brandProperty in brands) {
        const brand = brands[brandProperty];

        innerExtractChannels(brand);
      }
    }
  };

  innerExtractChannels(dataBrand);

  sortByKey(result, 'name');

  return result;
};

export const extractBrands = (dataRegion: any): any[] => {
  let result: any[] = [];

  const innerExtractBrands = (data: any): void => {
    const { brands } = data;

    for (const property in brands) {
      const brand = brands[property];

      const channels = extractChannels(brand);

      result.push({
        name: brand.name,
        id: brand.id,
        address: brand.address,
        channels,
      });

      if (!isEmpty(brand.brands)) {
        innerExtractBrands(brand);
      }
    }
  };

  innerExtractBrands(dataRegion);

  sortByKey(result, 'name');

  return result;
};

export const getChannelsMetadata = async (brandAddress: string | null) => {
  try {
    const { data } = await apiHierarchy().get(`/node/${brandAddress}`);

    return data.channels;
  } catch (err) {
    console.error('getChannelsMetadata', err);

    return null;
  }
};

export const getSingleChannelJobsData = async (
  scope: string,
  prefix: string,
  textList: string[]
) => {
  let link = `all-old?scope=${scope}&prefix=${prefix}`;

  textList.forEach((text) => {
    link += `&text=${text}`;
  });

  let jobSearchAll = await apiMadHatter().get(`/search/${link}`);

  return jobSearchAll.data;
};
