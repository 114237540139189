import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',

      '& > div': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      },

      '&$isFullPage': {
        height: '100%',
      },

      '&$isInline': {
        justifyContent: 'flex-start',
        marginTop: theme.spacing(1),

        '& > div': {
          flexDirection: 'row',
          alignItems: 'center',
        },

        '& $icon': {
          margin: theme.spacing(1),

          '& .MuiSvgIcon-root': {
            fontSize: theme.typography.pxToRem(24),
          },
        },

        '& $label': {
          marginBottom: theme.spacing(1),
          maxWidth: 'none',
          paddingRight: theme.spacing(1),
        },
      },
    },

    icon: {
      margin: theme.spacing(1, 'auto'),

      '& .MuiSvgIcon-root': {
        fontSize: theme.typography.pxToRem(42),
        color: '#e2a04c',
      },
    },

    label: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      letterSpacing: 2,
      maxWidth: 250,
      textAlign: 'center',
      lineHeight: '140%',
      fontSize: '.8em',
    },

    isFullPage: {},
    isInline: {},
  }),
  { name: 'Message' }
);

type tmessageTypes = {
  [key: string]: string | JSX.Element;
};

const messageTextTypes: tmessageTypes = {
  DEFAULT: 'Please wait',
  NO_PLATFORM: 'No platform to display, please contact system administrator',
  NO_ACCESS_TO_REGIONS:
    'No regions to display, maybe you do not have access, please contact system administrator',
  NO_REGIONS: 'No regions defined for this platform',
  NO_BRANDS: 'No brands defined for this region',
  NO_CHANNELS: 'No channels defined for this brand',
  NO_CHANNELS_SETUP: 'No channels with days for advanced schedule',
  CHANNEL_DAYS_EMPTY: 'No days for advanced schedule',
  ASRUNS_EMPTY: 'As-Runs not enabled for this channel',
  ASRUNS_DAYS_EMPTY: 'No days set for As-Runs',
  ASRUNS_EXCEEDS_DAYS: 'Showing last 180 As-Runs days',
  ASRUNS_AVAIABLE_FOR_DATE: 'As-Run available for download',
  ASRUNS_NOT_AVAIABLE_FOR_DATE: 'As-Run not available for this date',
  ASRUNS_DATE_NOTATION: 'Channel date notation not set',
  UPLOAD_OVERWRITE: 'Are you sure you want to overwrite?',
  NO_REGION_SELECTED: 'Select a region',
  NO_BRAND_SELECTED: 'Select a brand',
  NO_CHANNEL_SELECTED: 'Select a channel',
  NO_STREAM_URL: 'Missing Stream URL',
  INVALID_STREAM_URL: 'Stream URL is invalid',
};

const messageIconTypes: tmessageTypes = {
  DEFAULT: <CircularProgress color="primary" />,
  NO_PLATFORM: <ErrorOutlineIcon />,
  NO_ACCESS_TO_REGIONS: <NoEncryptionIcon />,
  NO_BRANDS: <ErrorOutlineIcon />,
  NO_REGIONS: <LocationOffIcon />,
  NO_CHANNELS: <ErrorOutlineIcon />,
  NO_CHANNELS_SETUP: <ErrorOutlineIcon />,
  CHANNEL_DAYS_EMPTY: <ErrorOutlineIcon />,
  ASRUNS_EMPTY: <ErrorOutlineIcon />,
  ASRUNS_DAYS_EMPTY: <ErrorOutlineIcon />,
  ASRUNS_EXCEEDS_DAYS: <ErrorOutlineIcon />,
  ASRUNS_AVAIABLE_FOR_DATE: <CloudDownloadIcon />,
  ASRUNS_NOT_AVAIABLE_FOR_DATE: <ErrorOutlineIcon />,
  ASRUNS_DATE_NOTATION: <ErrorOutlineIcon />,
  UPLOAD_OVERWRITE: <HelpOutlineIcon />,
  // NO_REGION_SELECTED: <ErrorOutlineIcon />,
  // NO_BRAND_SELECTED: <ErrorOutlineIcon />,
  // NO_CHANNEL_SELECTED: <ErrorOutlineIcon />,
};

interface Props {
  type: string;
  isFullPage?: boolean;
  inline?: boolean;
}

const Message: React.FC<Props> = ({
  isFullPage,
  inline,
  type,
}): JSX.Element => {
  const styles = useStyles();

  return (
    <div
      className={classNames(styles.root, {
        [styles.isFullPage]: isFullPage,
        [styles.isInline]: inline,
      })}
    >
      <div>
        {messageIconTypes[type] && (
          <div className={styles.icon}>{messageIconTypes[type]}</div>
        )}
        {messageTextTypes[type] && (
          <div className={styles.label}>{messageTextTypes[type]}</div>
        )}
      </div>
    </div>
  );
};

export default Message;
