import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCoffee,
  faExclamationCircle,
  faExclamationTriangle,
  faFlagCheckered,
  faCloudUploadAlt,
} from '@fortawesome/free-solid-svg-icons';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core';
import { IChannelDay } from '../../../models/hierarchy/channelDay';
import { JobStatusTypes } from '../../../models/hierarchy';
import { showErrorNotification } from '../../../utils';
import Message from '../../../components/message/Message';
import PreviewButton from './PreviewButton';

const useStyles = makeStyles(
  (theme) => ({
    body: {
      borderRadius: 3,
      background: '#c5c9cd',
      width: '100%',
      minHeight: theme.spacing(10),
      padding: '1em',
      boxSizing: 'border-box',
      lineHeight: '1.25em',
      border: '0.2rem solid transparent',
      display: 'flex',
      alignItems: 'center',
      color: '#000',

      '&$dropZoneActive': {
        background: '#b8b8b8',
        border: '0.2rem dashed #87ab77',
      },
    },

    wrapper: {
      marginBottom: theme.spacing(4),
    },

    labelDate: {
      borderRadius: 3,
      display: 'inline-block',
      padding: theme.spacing(0.5, 1),

      background: '#fff',
      border: '2px solid #000',
      marginRight: '1em',

      letterSpacing: '2px',
      backgroundColor: theme.palette.background.default,

      '&$complete': {
        borderColor: '#87ab77',
      },

      '&$error': {
        borderColor: '#cc2e2b',
      },

      '&$inProgress': {
        borderColor: '#347de2',
      },

      '&$warning': {
        borderColor: '#e48b06',
      },
    },

    channelName: {
      '&$complete': {
        color: '#87ab77',
      },

      '&$error': {
        color: '#cc2e2b',
      },

      '&$inProgress': {
        color: '#347de2',
      },

      '&$warning': {
        color: '#e48b06',
      },
    },

    jobStatusSection: {
      color: '#000000',

      '&$complete': {
        color: '#87ab77',
      },

      '&$error, & $error': {
        color: '#cc2e2b',
      },

      '&$inProgress': {
        color: '#347de2',
      },

      '&$warning': {
        color: '#e48b06',
      },

      '&$empty': {
        color: 'gray',
      },
    },

    scheduleText: {
      color: '#fff',
      fontWeight: 'bold',
      marginRight: '1em',
    },

    jobTypeText: {
      marginRight: '1em',
    },

    statusText: {
      marginRight: '1em',
    },

    shortMessage: {
      marginLeft: '1em',
    },

    jobIdText: {
      marginRight: '1em',
    },

    calendarIcon: {
      marginRight: '1em',
    },

    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '0.5em',
      justifyContent: 'space-between',
    },

    confirmModal: {
      position: 'absolute',
      width: '10rem',
      height: '10rem',
      background: '#fff',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },

    dropZoneActive: {},
    complete: {},
    error: {},
    inProgress: {},
    warning: {},
    empty: {},
  }),
  { name: 'ChannelDay' }
);

interface Props {
  channelDay: IChannelDay;
  channelAddress: string | null | undefined;
  channelFileExtension?: string | null;
}

const ChannelDay: React.FC<Props> = observer((props) => {
  const { channelDay, channelAddress, channelFileExtension } = props;
  const styles = useStyles();
  const {
    labelDate,
    jobSignal,
    isChannelDayValidToUploadSameDay,
    hasExpectedFileNameStructure,
    saveScheduleFile,
    isLoading,
    isUploading,
    expectedFileName,
    fileName,
    hasErrors,
    errors,
  } = channelDay;

  const jobId = jobSignal?.jobId;
  const jobStatus = jobSignal?.jobStatus;
  const jobType = jobSignal?.jobType;
  const jobClass = jobSignal?.jobClass;
  const shortMessage = jobSignal?.shortMessage;

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  let scheduleFile = useRef<File | null>(null);

  const closeConfirmModal = () => {
    setIsOpenConfirm(false);
  };

  const openConfirmModal = () => {
    setIsOpenConfirm(true);
  };

  const handleConfirmFile = () => {
    if (channelAddress && scheduleFile.current) {
      saveScheduleFile(channelAddress, scheduleFile.current);
    }
    closeConfirmModal();
  };

  const isFileValid = (file: File): boolean => {
    const fileNameArray = file.name.split('.');
    const uploadedFileExtension = fileNameArray[fileNameArray.length - 1];

    if (!isChannelDayValidToUploadSameDay()) {
      showErrorNotification('Can not upload on the same day');

      return false;
    }

    if (
      channelFileExtension &&
      channelFileExtension.toUpperCase() !== uploadedFileExtension.toUpperCase()
    ) {
      showErrorNotification(
        `File extension not supported, please use a .${channelFileExtension} file`
      );

      return false;
    }

    if (!hasExpectedFileNameStructure(file.name)) {
      showErrorNotification('File does not have the expected name');

      return false;
    }

    if (file.size === 0) {
      showErrorNotification('File is empty');

      return false;
    }

    return true;
  };

  const onDrop = (acceptedFiles: File[]) => {
    if (isUploading) {
      showErrorNotification('Upload is already in progress');

      return;
    }

    const file: File = acceptedFiles[0];

    if (isFileValid(file)) {
      scheduleFile.current = file;

      if (jobSignal) {
        openConfirmModal();
      } else {
        handleConfirmFile();
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const jobStatusIconElem = (() => {
    switch (jobStatus) {
      case JobStatusTypes.inProgress:
        return <FontAwesomeIcon icon={faCoffee} />;
      case JobStatusTypes.completedWithWarnings:
        return <FontAwesomeIcon icon={faExclamationTriangle} />;
      case JobStatusTypes.error:
        return <FontAwesomeIcon icon={faExclamationCircle} />;
      case JobStatusTypes.complete:
        return <FontAwesomeIcon icon={faFlagCheckered} />;

      default:
        return <FontAwesomeIcon icon={faFlagCheckered} />;
    }
  })();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div>
          <span
            className={classNames([
              styles.labelDate,
              {
                [styles.complete]: jobStatus === JobStatusTypes.complete,
                [styles.error]: jobStatus === JobStatusTypes.error,
                [styles.inProgress]: jobStatus === JobStatusTypes.inProgress,
                [styles.warning]: jobStatus === JobStatusTypes.completedWithWarnings,
              },
            ])}
          >
            {labelDate}
          </span>
          <FontAwesomeIcon
            className={styles.calendarIcon}
            icon={faCalendarAlt}
          />
          <span className={styles.scheduleText}>Schedule: </span>
          <span
            className={classNames([
              styles.channelName,
              {
                [styles.complete]: jobStatus === JobStatusTypes.complete,
                [styles.error]: jobStatus === JobStatusTypes.error,
                [styles.inProgress]: jobStatus === JobStatusTypes.inProgress,
                [styles.warning]: jobStatus === JobStatusTypes.completedWithWarnings,
              },
            ])}
          >
            {fileName ? (
              <>
                {`${fileName}${
                  channelFileExtension ? `.${channelFileExtension}` : ''
                }`}
              </>
            ) : (
              expectedFileName && <>expectedFileName: {expectedFileName}</>
            )}
          </span>
        </div>
        <div>
          <PreviewButton
            address={channelAddress}
            file={fileName || null}
            enabled={jobStatus && jobStatus === JobStatusTypes.complete}
          />
        </div>
      </div>
      <div
        {...getRootProps()}
        className={classNames([
          styles.body,
          {
            [styles.dropZoneActive]: isDragActive,
          },
        ])}
      >
        {isLoading ? (
          <CircularProgress size={28} color="primary" />
        ) : (
          <div>
            <input {...getInputProps()} />
            <div
              className={classNames([
                styles.jobStatusSection,
                {
                  [styles.complete]: jobStatus === JobStatusTypes.complete,
                  [styles.error]: jobStatus === JobStatusTypes.error,
                  [styles.inProgress]: jobStatus === JobStatusTypes.inProgress,
                  [styles.warning]: jobStatus === JobStatusTypes.completedWithWarnings,
                  [styles.empty]: jobSignal === null,
                },
              ])}
            >
              {jobSignal ? (
                <>
                  <span className={styles.jobTypeText}>
                    Job Type: {jobType}
                  </span>
                  <span className={styles.statusText}>Status: {jobStatus}</span>
                  {jobStatusIconElem}
                  <span className={styles.shortMessage}>
                    {jobStatus === JobStatusTypes.error || jobStatus === JobStatusTypes.completedWithWarnings
                      ? shortMessage
                      : null}
                  </span>
                </>
              ) : (
                <>
                  {hasErrors ? (
                    <>
                      {errors.scheduleParserID && (
                        <div className={styles.error}>
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <span> scheduleParserID not set</span>
                        </div>
                      )}
                      {errors.inputFileDateNotation && (
                        <div className={styles.error}>
                          <FontAwesomeIcon icon={faExclamationCircle} />
                          <span> inputFileDateNotation not set</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faCloudUploadAlt} />
                      <span> Drop a schedule file to begin workflow</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div>
              {jobSignal && (
                <>
                  {jobId && (
                    <>
                      <span>jobId: </span>
                      <span className={styles.jobIdText}>{jobId}</span>
                    </>
                  )}
                  {jobClass && (
                    <>
                      <span>jobClass: </span>
                      <span>{jobClass}</span>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Dialog
        open={isOpenConfirm}
        onClose={closeConfirmModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Message type="UPLOAD_OVERWRITE" inline />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By confirming, you will going to overwrite the current schedule. A
            new process will begin
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal} style={{ color: '#bdbdbd' }}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmFile}
            variant="contained"
            color="primary"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default ChannelDay;
