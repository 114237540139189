import React from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { initStorage } from '@idealmediaworks/lookingglass-ui-localstorage';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { light, dark } from '@idealmediaworks/lookingglass-ui-theme';
import {
  Layout,
  LayoutProvider,
  KeycloakProvider,
  AccountProvider,
  MyProfileView,
  LogoutView,
  accountRoles,
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
} from '@idealmediaworks/lookingglass-ui-framework';

import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ScheduleIcon from '@material-ui/icons/Schedule';

import SchedulesPage from '../pages/schedules';
import AsRunsPage from '../pages/asruns';
import NoAccessPage from '../pages/noAccess';
import PrivateRoute from './common/PrivateRoute';
import AppInit from '../components/AppInit';
import panels from '../panels';
import SideMenu from './common/sidemenu/SideMenu';

import packageJson from '../../package.json';

const config = {
  storage: {
    key: 'rbth',
  },
};

const themes = {
  dark,
  light,
};

const navigation = {
  primary: {
    paths: ['/home'],
    items: [
      {
        id: 'schedules',
        title: 'Schedules',
        icon: ScheduleIcon,
        roles: [
          accountRoles.aliceOperator,
          accountRoles.aliceEngineer,
          accountRoles.aliceAdmin,
          accountRoles.rabbitholeSchedules,
        ],
        to: '/home',
      },
      {
        id: 'asruns',
        title: 'As-Runs',
        icon: FormatListNumberedIcon,
        roles: [
          accountRoles.aliceOperator,
          accountRoles.aliceEngineer,
          accountRoles.aliceAdmin,
          accountRoles.rabbitholeAsruns,
        ],
        to: '/asruns',
      },
    ],
  },
  account: {
    paths: ['/profile', '/logout'],
    items: [
      {
        id: 'profile',
        title: 'My Profile',
        icon: PersonIcon,
        roles: [
          accountRoles.aliceOperator,
          accountRoles.aliceEngineer,
          accountRoles.aliceAdmin,
          accountRoles.rabbitholeUser,
        ],
        to: '/profile',
      },
      {
        id: 'logout',
        title: 'Logout',
        icon: ExitToAppIcon,
        roles: [
          accountRoles.aliceOperator,
          accountRoles.aliceEngineer,
          accountRoles.aliceAdmin,
          accountRoles.rabbitholeUser,
        ],
        to: '/logout',
      },
    ],
  },
};

initStorage(config.storage);

const AppRouter = () => {

  return (
    <LayoutProvider
      config={config}
      themes={themes}
      panels={panels}
      navigation={navigation}
      version={packageJson.version}
    >
      <KeycloakProvider>
        <AccountProvider
          platformRole={[
            accountRoles.aliceOperator,
            accountRoles.aliceEngineer,
            accountRoles.aliceAdmin,
            accountRoles.rabbitholeUser,
          ]}
        >
          <Router>
            <AppInit>
              <Layout>
                <Redirect from="/" to="/home" />
                <PrivateRoute
                  roles={[
                    accountRoles.aliceOperator,
                    accountRoles.aliceEngineer,
                    accountRoles.aliceAdmin,
                    accountRoles.rabbitholeSchedules,
                  ]}
                  path="/home"
                  component={SchedulesPage}
                />
                <PrivateRoute
                  roles={[
                    accountRoles.aliceOperator,
                    accountRoles.aliceEngineer,
                    accountRoles.aliceAdmin,
                    accountRoles.rabbitholeAsruns,
                  ]}
                  path="/asruns"
                  component={AsRunsPage}
                />
                <PrivateRoute
                  roles={[
                    accountRoles.aliceOperator,
                    accountRoles.aliceEngineer,
                    accountRoles.aliceAdmin,
                    accountRoles.rabbitholeUser,
                  ]}
                  path="/profile"
                  component={MyProfileView}
                />
                <Route path="/contact" component={NoAccessPage} />
                <Route path="/logout" component={LogoutView} />

                <SideMenu />
              </Layout>
            </AppInit>
          </Router>
        </AccountProvider>
      </KeycloakProvider>
    </LayoutProvider>
  );
};

export default observer(AppRouter);
