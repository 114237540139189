declare global {
  interface Window {
    _env_: {
      REACT_APP_UI_SERVICE: string;
      REACT_APP_KC_URL: string;
      REACT_APP_KC_REALM: string;
      REACT_APP_KC_RESOURCE: string;
      REACT_APP_RH_ROLE: string;
      REACT_APP_SCHEDULE_GROUP: string;
      REACT_APP_MH_URL: string;
      REACT_APP_ARQ_SERVICE: string;
      REACT_APP_ABSOLEM_APP: string;
    };
  }
}

// eslint-disable-next-line no-underscore-dangle
const envVar = window._env_;

export const uiServiceUrl = envVar?.REACT_APP_UI_SERVICE
  ? `${envVar.REACT_APP_UI_SERVICE}`
  : '//localhost:9080';

const kcURL = envVar?.REACT_APP_KC_URL
  ? `${envVar.REACT_APP_KC_URL}`
  : 'https://kc.idealcloud.tv/auth';

const kcRealm = envVar?.REACT_APP_KC_REALM
  ? `${envVar.REACT_APP_KC_REALM}`
  : 'alice';

const kcClient = envVar?.REACT_APP_KC_RESOURCE
  ? `${envVar.REACT_APP_KC_RESOURCE}`
  : 'rabbithole-app';

// keycloak init options
export const keycloakInitOptions = {
  url: kcURL,
  realm: kcRealm,
  clientId: kcClient,
};

export const scheduleGroup = envVar?.REACT_APP_SCHEDULE_GROUP
  ? `${envVar?.REACT_APP_SCHEDULE_GROUP}`
  : 'raw_schedules';

export const madhatterServiceUrl = envVar?.REACT_APP_MH_URL
  ? `${envVar.REACT_APP_MH_URL}`
  : '//localhost:8080';

export const arqServiceUrl = envVar?.REACT_APP_ARQ_SERVICE
  ? `${envVar.REACT_APP_ARQ_SERVICE}`
  : '//localhost:8087';

export const absolemAppUrl = envVar?.REACT_APP_ABSOLEM_APP
  ? `${envVar.REACT_APP_ABSOLEM_APP}`
  : '//localhost:9097';
