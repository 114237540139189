import React from 'react';
import { observer } from 'mobx-react';
import { useHierarchyStore } from '../../models';
import TabList from '../../components/tabsList';
// import Preloader from '../../components/preloader/Preloader';
import Message from '../../components/message/Message';

const BrandsArea: React.FC = ({ children }): JSX.Element => {
  const { selectedRegion } = useHierarchyStore();

  const handleChange = (id: string) => {
    selectedRegion?.changeSelectedBrand(id);
  };

  if (!selectedRegion?.id) {
    return <Message type="NO_REGION_SELECTED" isFullPage />;
  }

  if (selectedRegion.brands.length === 0) {
    return <Message type="NO_BRANDS" isFullPage />;
  }

  // if (!selectedRegion.selectedBrand?.id) {
  //   return <>No brand selected</>;
  // }

  // if (selectedRegion?.selectedBrand?.isLoading) {
  //   return <Preloader isFullPage />;
  // }

  return (
    <TabList
      selectedItem={selectedRegion.selectedBrand}
      list={selectedRegion.brands}
      changeSelectedItem={handleChange}
    />
  );
};

export default observer(BrandsArea);
