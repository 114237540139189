// eslint-disable-file @typescript-eslint\explicit-function-return-type
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import type { RouteProps } from 'react-router-dom';

interface PrivateRouteParams extends RouteProps {
    component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    roles: string[];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PrivateRoute = ({
    component: Component,
    roles,
    ...rest }: PrivateRouteParams) => {
    const { keycloak } = useKeycloak();

    const isAutherized = (allowedRoles: string[]) => {
        if (keycloak && allowedRoles) {
            return allowedRoles.some(r => {
                // In keycloak there are two ways of assiging roles to the user 
                // You can assign roles to realm & client 
                // In that case you have to use both scinarios with hasRealmRole & hasResourceRole
                const realm = keycloak.hasRealmRole(r);
                const resource = keycloak.hasResourceRole(r);

                return realm || resource;
            });
        }

        return false;
    };

    return (
      <Route
        {...rest}
        render={props => {
                return isAutherized(roles)
                    ? <Component {...props} />
                    : (keycloak?.authenticated ? (
                      <Redirect
                        to={{
                                pathname: '/contact',
                            }}
                      />
                    ) : (
                      <Redirect
                        to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                      />
                        )

                    );
            }}
      />
    );
};

export default PrivateRoute;
